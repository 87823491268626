import React from "react"
import Layout from "../layouts"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {
  BreadCrumb,
  Container,
  Section,
  MarkdownBody,
  MarkdownContent,
} from "../components/Section"
import {
  HeroContainer,
  Hero,
  Background,
  HeroCaption,
  HeroTitle,
  HeroDescription,
} from "../components/Hero"

const StaticPage = ({ location }) => (
  <Layout isHeader={true} location={location}>
    <Seo
      title="Privacy Policy - Liberty Metal Structures"
      description="Metal Building Structures takes data privacy very seriously. Our privacy policy is designed to help you understand how to collect and process data."
    />
    <Section
      xpt="114px"
      mpt="94px"
      pt="64px"
      xpb="0"
      mpb="0"
      pb="0"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <HeroContainer>
          <Hero>
            <Background>
              <StaticImage
                src="../images/privacy-banner-bg.jpg"
                placeholder="blurred"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="privacy-banner-bg"
              />
            </Background>
            <HeroCaption>
              <BreadCrumb className="white" position="static" translate="0">
                <Link to="/">Home</Link>
                <span>Privacy Policy</span>
              </BreadCrumb>
              <HeroTitle>Privacy Policy</HeroTitle>
              <HeroDescription maxWidth="800px">
                <p>
                  This Application collects some Personal Data from its Users.
                </p>
              </HeroDescription>
            </HeroCaption>
          </Hero>
        </HeroContainer>
      </Container>
    </Section>
    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <MarkdownBody>
          <MarkdownContent maxWidth="1496px">
            <h2>DATA CONTROLLER AND OWNER</h2>
            <p>
              Liberty Metal Structures
              <br />
              <a href="tel:8004407309">(800) 440-7309</a>
              <br />
              796 W Lebanon St. Mount Airy, NC 27030
            </p>

            <h2>TYPES OF DATA COLLECTED</h2>
            <p>
              Among the types of Personal Data that this Application collects,
              by itself or through third parties, there are: cookies, usage
              data, email address, first name, last name, address, password,
              state, ZIP/Postal code and city.
            </p>
            <p>
              The Personal Data may be freely provided by the User, or collected
              automatically when using this Application.
            </p>
            <p>
              Failure to provide certain Personal Data may make it impossible
              for this Application to provide its services.
            </p>
            <p>
              Users are responsible for any Personal Data of third parties
              obtained, published or shared through this Application and confirm
              that they have the third party’s consent to provide the Data to
              the Owner.
            </p>

            <h2>MODE AND PLACE OF PROCESSING THE DATA</h2>
            <h3>METHODS OF PROCESSING</h3>
            <p>
              The Data Controller processes the Data of Users in a proper manner
              and shall take appropriate security measures to prevent
              unauthorized access, disclosure, modification, or unauthorized
              destruction of the Data.
            </p>
            <p>
              The Data processing is carried out using computers and/or IT
              enabled tools, following organizational procedures and modes
              strictly related to the purposes indicated. In addition to the
              Data Controller, in some cases, the Data may be accessible to
              certain types of persons in charge, involved with the operation of
              the site (administration, sales, marketing, legal, system
              administration) or external parties (such as third party technical
              service providers, mail carriers, hosting providers, IT companies,
              communications agencies) appointed, if necessary, as Data
              Processors by the Owner. The updated list of these parties may be
              requested from the Data Controller at any time.
            </p>

            <h3>PLACE</h3>
            <p>
              The Data is processed at the Data Controller’s operating offices
              and in any other places where the parties involved with the
              processing are located. For further information, please contact
              the Data Controller.
            </p>

            <h3>RETENTION TIME</h3>
            <p>
              The Data is kept for the time necessary to provide the service
              requested by the User, or stated by the purposes outlined in this
              document, and the User can always request that the Data Controller
              suspend or remove the data.
            </p>

            <h3>THE USE OF THE COLLECTED DATA</h3>
            <p>
              The Data concerning the User is collected to allow the Owner to
              provide its services, as well as for the following purposes:
              Analytics, Interaction with online survey platforms, Remarketing
              and Behavioral Targeting, Interaction with live chat platforms,
              Heat mapping, Managing support and contact requests, Registration
              and authentication, Content performance and features testing (A/B
              testing), Handling payments and Interaction with external social
              networks and platforms.
            </p>
            <p>
              The Personal Data used for each purpose is outlined in the
              specific sections of this document.
            </p>

            <h2>DETAILED INFORMATION ON THE PROCESSING OF PERSONAL DATA</h2>
            <p>
              Personal Data is collected for the following purposes and using
              the following services:
            </p>

            <h3>ANALYTICS</h3>
            <p>
              The services contained in this section enable the Owner to monitor
              and analyze web traffic and can be used to keep track of User
              behavior.
            </p>

            <h3>GOOGLE ANALYTICS (GOOGLE, INC.)</h3>
            <p>
              Google Analytics is a web analysis service provided by Google,
              Inc. (“Google”). Google utilizes the Data collected to track and
              examine the use of this Application, to prepare reports on its
              activities and share them with other Google services. Google may
              use the Data collected to contextualize and personalize the ads of
              its own advertising network.
              <br />
              Personal Data collected: Cookies and Usage data.
              <br />
              Place of processing: US – Privacy Policy – Opt Out
            </p>

            <h3>HEAT MAPPING</h3>
            <p>
              Heat Mapping services are used to display the areas of a page
              where Users most frequently move the mouse or click. This shows
              where the points of interest are. These services make it possible
              to monitor and analyze web traffic and keep track of User
              behavior.
            </p>

            <h3>CRAZY EGG (CRAZYEGG)</h3>
            <p>
              Crazy Egg is a heat mapping service provided by Crazy Egg, Inc.
              Personal Data collected: Cookies and Usage data. Place of
              processing: US – Privacy Policy
            </p>

            <h3>INTERACTION WITH EXTERNAL SOCIAL NETWORKS AND PLATFORMS</h3>
            <p>
              This type of services allow interaction with social networks or
              other external platforms directly from the pages of this
              Application. The interaction and information obtained through this
              Application are always subject to the User’s privacy settings for
              each social network. This type of service might still collect
              traffic data for the pages where the service is installed, even
              when Users do not use it.
            </p>

            <h3>FACEBOOK LIKE BUTTON AND SOCIAL WIDGETS (FACEBOOK, INC.)</h3>
            <p>
              The Facebook Like button and social widgets are services allowing
              interaction with the Facebook social network provided by Facebook,
              Inc.
              <br />
              Personal Data collected: Cookies and Usage data.
              <br />
              Place of processing: US – Privacy Policy
            </p>

            <h3>GOOGLE+ +1 BUTTON AND SOCIAL WIDGETS (GOOGLE, INC.)</h3>
            <p>
              The Google+ +1 button and social widgets are services allowing
              interaction with the Google+ social network provided by Google
              Inc.
              <br />
              Personal Data collected: Cookies and Usage data.
              <br />
              Place of processing: US – Privacy Policy
            </p>

            <h3>REMARKETING AND BEHAVIORAL TARGETING</h3>
            <p>
              This type of services allows this Application and its partners to
              inform, optimize and serve advertising based on past use of this
              Application by the User.
            </p>
            <p>
              This activity is performed by tracking Usage Data and by using
              Cookies, information that is transferred to the partners that
              manage the remarketing and behavioral targeting activity.
            </p>

            <h3>FACEBOOK CUSTOM AUDIENCE (FACEBOOK, INC.)</h3>
            <p>
              Facebook Custom Audience is a Remarketing and Behavioral Targeting
              service provided by Facebook, Inc. that connects the activity of
              this Application with the Facebook advertising network.
              <br />
              Personal Data collected: Cookies and email address.
              <br />
              Place of processing: US – Privacy Policy – Opt Out
            </p>

            <h3>ADWORDS REMARKETING (GOOGLE, INC.)</h3>
            <p>
              AdWords Remarketing is a Remarketing and Behavioral Targeting
              service provided by Google, Inc. that connects the activity of
              this Application with the Adwords advertising network and the
              Doubleclick Cookie.
              <br />
              Personal Data collected: Cookies and Usage data.
              <br />
              Place of processing: US – Privacy Policy – Opt Out
            </p>

            <h2>FURTHER INFORMATION ABOUT PERSONAL DATA</h2>
            <h3>THE SERVICE IS NOT DIRECTED TO CHILDREN UNDER THE AGE OF 13</h3>
            <p>
              Users declare themselves to be adult according to their applicable
              legislation. Minors may use this Application only with the
              assistance of a parent or guardian. Under no circumstance persons
              under the age of 13 may use this Application.
            </p>

            <h3>SELLING GOODS AND SERVICES ONLINE</h3>
            <p>
              The Personal Data collected is used to provide the User with
              services or to sell goods, including payment and possible
              delivery. The Personal Data collected to complete the payment may
              include the credit card, the bank account used for the transfer,
              or any other means of payment envisaged. The kind of Data
              collected by this Application depends on the payment system used.
            </p>

            <h2>ADDITIONAL INFORMATION ABOUT DATA COLLECTION AND PROCESSING</h2>
            <h3>LEGAL ACTION</h3>
            <p>
              The User’s Personal Data may be used for legal purposes by the
              Data Controller, in Court or in the stages leading to possible
              legal action arising from improper use of this Application or the
              related services. The User declares to be aware that the Data
              Controller may be required to reveal personal data upon request of
              public authorities.
            </p>

            <h3>ADDITIONAL INFORMATION ABOUT USER’S PERSONAL DATA</h3>
            <p>
              In addition to the information contained in this privacy policy,
              this Application may provide the User with additional and
              contextual information concerning particular services or the
              collection and processing of Personal Data upon request.
            </p>

            <h3>SYSTEM LOGS AND MAINTENANCE</h3>
            <p>
              For operation and maintenance purposes, this Application and any
              third party services may collect files that record interaction
              with this Application (System logs) or use for this purpose other
              Personal Data (such as IP Address).
            </p>

            <h3>INFORMATION NOT CONTAINED IN THIS POLICY</h3>
            <p>
              More details concerning the collection or processing of Personal
              Data may be requested from the Data Controller at any time. Please
              see the contact information at the beginning of this document.
            </p>

            <h3>THE RIGHTS OF USERS</h3>
            <p>
              Users have the right, at any time, to know whether their Personal
              Data has been stored and can consult the Data Controller to learn
              about their contents and origin, to verify their accuracy or to
              ask for them to be supplemented, cancelled, updated or corrected,
              or for their transformation into anonymous format or to block any
              data held in violation of the law, as well as to oppose their
              treatment for any and all legitimate reasons. Requests should be
              sent to the Data Controller at the contact information set out
              above.
            </p>
            <p>This Application does not support “Do Not Track” requests.</p>
            <p>
              To determine whether any of the third party services it uses honor
              the “Do Not Track” requests, please read their privacy policies.
            </p>

            <h3>CHANGES TO THIS PRIVACY POLICY</h3>
            <p>
              The Data Controller reserves the right to make changes to this
              privacy policy at any time by giving notice to its Users on this
              page. It is strongly recommended to check this page often,
              referring to the date of the last modification listed at the
              bottom. If a User objects to any of the changes to the Policy, the
              User must cease using this Application and can request that the
              Data Controller remove the Personal Data. Unless stated otherwise,
              the then-current privacy policy applies to all Personal Data the
              Data Controller has about Users.
            </p>

            <h3>INFORMATION ABOUT THIS PRIVACY POLICY</h3>
            <p>
              The Data Controller is responsible for this privacy policy,
              prepared starting from the modules provided by GoDaddy and hosted
              on GoDaddy’s servers.
            </p>

            <h2>DEFINITIONS AND LEGAL REFERENCES</h2>
            <h3>PERSONAL DATA (OR DATA)</h3>
            <p>
              Any information regarding a natural person, a legal person, an
              institution or an association, which is, or can be, identified,
              even indirectly, by reference to any other information, including
              a personal identification number.
            </p>

            <h3>USAGE DATA</h3>
            <p>
              Information collected automatically from this Application (or
              third party services employed in this Application), which can
              include: the IP addresses or domain names of the computers
              utilized by the Users who use this Application, the URI addresses
              (Uniform Resource Identifier), the time of the request, the method
              utilized to submit the request to the server, the size of the file
              received in response, the numerical code indicating the status of
              the server’s answer (successful outcome, error, etc.), the country
              of origin, the features of the browser and the operating system
              utilized by the User, the various time details per visit (e.g.,
              the time spent on each page within the Application) and the
              details about the path followed within the Application with
              special reference to the sequence of pages visited, and other
              parameters about the device operating system and/or the User’s IT
              environment.
            </p>

            <h3>USER</h3>
            <p>
              The individual using this Application, which must coincide with or
              be authorized by the Data Subject, to whom the Personal Data
              refers.
            </p>

            <h3>DATA SUBJECT</h3>
            <p>The legal or natural person to whom the Personal Data refers.</p>

            <h3>DATA PROCESSOR (OR DATA SUPERVISOR)</h3>
            <p>
              The natural person, legal person, public administration or any
              other body, association or organization authorized by the Data
              Controller to process the Personal Data in compliance with this
              privacy policy.
            </p>

            <h3>DATA CONTROLLER (OR OWNER)</h3>
            <p>
              The natural person, legal person, public administration or any
              other body, association or organization with the right, also
              jointly with another Data Controller, to make decisions regarding
              the purposes, and the methods of processing of Personal Data and
              the means used, including the security measures concerning the
              operation and use of this Application. The Data Controller, unless
              otherwise specified, is the Owner of this Application.
            </p>

            <h3>THIS APPLICATION</h3>
            <p>
              The hardware or software tool by which the Personal Data of the
              User is collected.{" "}
            </p>

            <h3>COOKIES</h3>
            <p>Small piece of data stored in the User’s device.</p>

            <h3>LEGAL INFORMATION</h3>
            <p>
              Notice to European Users: this privacy statement has been prepared
              in fulfillment of the obligations under Art. 10 of EC Directive n.
              95/46/EC, and under the provisions of Directive 2002/58/EC, as
              revised by Directive 2009/136/EC, on the subject of Cookies.
            </p>
            <p>This privacy policy relates solely to this Application.</p>
          </MarkdownContent>
        </MarkdownBody>
      </Container>
    </Section>
  </Layout>
)

export default StaticPage
